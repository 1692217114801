import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faApple,
	faInstagram,
	faSoundcloud,
	faSpotify,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { styled } from "styled-components";
import { HeaderProps, SocialIcons } from "./Header";
import { SmallText } from "../styles/styledComponents";



const StyledFooter = styled.footer<HeaderProps>`
	fontFamily: ${props => props.theme.fontFamily};
	background: ${props => props.theme.backgroundColour};
	color: ${props => props.theme.secondaryColorText};
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: calc(5px + 2vmin);
	margin-top: -20px;
	img {
		margin-top: 5px;
		margin-bottom: 5px;
	}
	h1 {
		font-size: large;
	}
`

function Footer() {
	return (
		<StyledFooter isOpen={false} headerOrFooter="footer">
			<SmallText>contact us at floriginmusic@gmail.com</SmallText>
			<SocialIcons isOpen={false} headerOrFooter="footer">
			
				<a href="https://www.youtube.com/channel/UCw-4Hs42k567JbGiEGyBIFw">
					<FontAwesomeIcon icon={faYoutube} />
				</a>
				<a href="https://open.spotify.com/artist/2q5bQQI9tAIkAgzzU5JzdA?si=U8WUFT8sRE-IGR7IMfgu-g&referral=labelaffiliate&utm_source=1011lwISp5nj&utm_medium=Indie_Distrokid&utm_campaign=labelaffiliate&nd=1">
					<FontAwesomeIcon icon={faSpotify} />
				</a>
				<a href="https://www.instagram.com/floriginmusic/">
					<FontAwesomeIcon icon={faInstagram} />
				</a>
				<a href="https://music.apple.com/us/artist/florigin/1522550336">
					<FontAwesomeIcon icon={faApple} />
				</a>
				<a href="https://soundcloud.com/user-412069159">
					<FontAwesomeIcon icon={faSoundcloud} />
				</a>
				</SocialIcons>
		</StyledFooter>
	);
}

export default Footer;
