import { useState, useEffect } from "react";
import { getContentful } from "../lib/getContentful";
import GigList from "./GigList";
import GigMap from "./GigMap";
import { Title2 } from "../styles/styledComponents";

export type Gig = {
	title: string;
	description: string;
	citycountry: string;
	location: {
		lat: number;
		lon: number;
	};
	dateAndTime: string;
	link: string;
	photo: {
		title: string;
		description: string;
		url: string;
		width: number;
		height: number;
	};
};

type Gigs = Record<"items", Gig[]>;

type GigsProps = {
	contentfulUrl: string;
	displayMap: boolean;
	displayPastGigs: boolean;
	testCase: 'none' | 'pastOnly' | 'mix'
};

export default function GigsList(props: GigsProps) {
	const [gigs, setGigs] = useState<Gigs | undefined>();
	const contentKey = "gigsCollection";
	const contentfulUrl = props.contentfulUrl;
	const displayMap = props.displayMap;
	const testCase = props.testCase;
	let displayPastGigs = props.displayPastGigs;

	// msw graphql interceptions needs operation type (query), and a name (GetGigs)
	// contentful works without the "query GetGigs" part
	// the next part is to allow testing different cases, returning different mock content
	// contentful 's graphQL API does not expect any variables except for type proofing
	let testingQueryVariableForGraphQL = ""
	let testingVariableforGraphQL =  ""
	if (testCase != 'none') {
		testingQueryVariableForGraphQL = `testing(testCase: ${testCase})`
		testingVariableforGraphQL = "($testCase: String!)"
	}

	const testQuery = ` query GetGigs($testCase: String!) {
		testing(testCase: ${testCase}) 
	}`

	const query = ` query GetGigs${testingVariableforGraphQL}
  {
    ${contentKey} {
        items {
        title
        description
				citycountry
        location {
          lat
          lon
        }
        dateAndTime 
        link
        photo {
          title
          description
          url
          width
          height
        }
		${testingQueryVariableForGraphQL}
        }
    }
    }
    `;

	useEffect(() => {
		const fetchData = async () => {
			const data = await getContentful<Record<string, Gigs>>(
				contentfulUrl,
				query
			);
			setGigs(data?.[contentKey]);
		};
		fetchData();
	}, [contentfulUrl, query]);

	if (!gigs) {
		return <p>Loading Gigs...</p>;
	}
	

	// if there's no future gigs, display past gigs always
	const num_future_gigs = gigs.items.filter((gig) => Date.parse(gig.dateAndTime) > Date.now()).length
	if (num_future_gigs === 0) {
		displayPastGigs = true
	}

	if (!displayPastGigs) {
		gigs.items = gigs.items
			.filter((gig) => Date.parse(gig.dateAndTime) > Date.now())
			.sort((a, b) => {
				return Date.parse(a?.dateAndTime) - Date.parse(b?.dateAndTime);
			});
	}

	if (displayMap) {
		return (
			<div>
				<GigList gigs={gigs.items} displayPastGigs={displayPastGigs} future_gigs={num_future_gigs>0} />
				<Title2>Find a future Florigin gig near you!</Title2>
				<GigMap gigs={gigs.items}></GigMap>
			</div>
		);
	}
	return (
		<div>
			<GigList gigs={gigs.items} displayPastGigs={displayPastGigs} future_gigs={num_future_gigs>0} />
		</div>
	);
}
