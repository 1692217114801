import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { defaultTheme } from "../Theme";
import { MainText, Title2 } from "../styles/styledComponents";
import { StyledDivGreen } from "./Gigs";



export default function Merch() {
	return (
		<div>
		<ThemeProvider theme={defaultTheme}>
			<StyledDivGreen>
			<Title2>Merch</Title2>
			<MainText>Coming soon...</MainText>
			</StyledDivGreen>
			</ThemeProvider>
		</div>
	);
}
