import React, { useState } from "react";
import "../styles/About.css";
import AboutContent from "../components/AboutContent";
import { contentfulUrl } from "../urls";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "../Theme";
import { Title2 } from "../styles/styledComponents";
import { StyledDivGreen } from "./Gigs";

export default function About() {
	return (
		<ThemeProvider theme={defaultTheme}>
			<StyledDivGreen>
		<div className="about">
			<Title2>About Us</Title2>
			<AboutContent contentfulUrl={contentfulUrl}></AboutContent>
		</div>
		</StyledDivGreen>
		</ThemeProvider>
	);
}
