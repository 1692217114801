import { GroupPhotos, GroupPhotosFields } from "../components/PhotoBlock";
import { getContentful } from "./getContentful";
import { contentfulUrl } from "../urls";

export async function getRandomPhoto() {
	const contentKey = "homePagePhotosCollection";
	const query = `
    {
        ${contentKey} {
            items {
            photo {
              title
              description
              contentType
              fileName
              size
              url
              width
              height
            }
            description 
            }
        }
        }
        `;

		const fetchData = async () => {
			const data = await getContentful<Record<string, GroupPhotos>>(
				contentfulUrl,
				query
			);
			return data?.[contentKey];
		};
		const photos = await fetchData();
        console.log('photos!')
        console.log(photos)
	
		return	photos?.items[Math.floor(Math.random() * photos?.items.length)]?.photo.url	
}