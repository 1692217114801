import React from "react";
import GigsList from "../components/Gigs";
import NewsItems from "../components/NewsItems";
import { contentfulUrl } from "../urls";
import styled, { ThemeProvider } from "styled-components";
import { defaultTheme } from "../Theme";
import floriginLogo from '../images/Logo_Hex.svg'
import greenBackground from '../images/BackgroundGreen.png'
import { Title2 } from "../styles/styledComponents";
import VideoCarousel from "../components/VideoCarousel";

interface HomeProps {
	backgroundImageUrl: string | undefined
}

interface HomePageBlockProps {
	useBackgroundImage: boolean
	color?: string
	backgroundImage?: string
	atTop?: boolean
}



export const HomePageBlock = styled.div<HomePageBlockProps>`
	background-image: ${props => props.useBackgroundImage ? `url(${props.backgroundImage})` : ''};
	background-color: ${props => !props.useBackgroundImage ? props.theme.backgroundColour : '' };
	width: 100%;
	height: ${props => props.theme.homeBlockHeight}px;
	display: grid;
	text-align: center;
	align-items: center;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	overflow-x: hidden;
	
	@media screen and (max-width: 768px) {
		width: auto;
		margin-top: ${props => props.atTop ? '15vh' : '0'};
		height: ${props => props.atTop ? '300px' : 'fit-content'};
	}
`

export const Logo = styled.img`
    width: 50%;
	padding: 0;
	margin-left: 25%;
	position: absolute;
    @media screen and (max-width: 768px) {
        width: 70%;
		margin-left: 15%;
    }
`

const StyledVideos = styled.div`
	display: grid;
	margin-left: 20%;
	width: 60%;
	@media screen and (max-width: 768px) {
		width: 90%;
		margin-left: 5%;
	}
	.react-multiple-carousel__arrow {
		border: 3px;
		border-color: ${props => props.theme.primaryColorText};
		border-style: solid;
	}
`

export default function Home({backgroundImageUrl}: HomeProps) {
	return (
		<ThemeProvider theme={defaultTheme}>
		<HomePageBlock useBackgroundImage={true} atTop={true} backgroundImage={backgroundImageUrl}>
		{/* <Logo src={floriginLogo} className="Florigin-logo" alt="Florigin" /> */}
		</HomePageBlock>
		<HomePageBlock useBackgroundImage={true} backgroundImage={greenBackground} >
			<GigsList
				contentfulUrl={contentfulUrl}
				displayMap={false}
				displayPastGigs={false}
				testCase="none"
			></GigsList>
		</HomePageBlock>
		<HomePageBlock useBackgroundImage={false} >
			<Title2 otherColor={true}>Recorded Live Sessions</Title2>
			<StyledVideos>
				<VideoCarousel contentfulUrl={contentfulUrl}></VideoCarousel>
			</StyledVideos>
		</HomePageBlock>
		<HomePageBlock useBackgroundImage={true} backgroundImage={greenBackground} >
		
			<NewsItems
				contentfulUrl={contentfulUrl}
				displayLatestOnly={true}
			></NewsItems>


		</HomePageBlock>


		</ThemeProvider>
	);
}
