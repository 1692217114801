import React from "react";
import PhotoBlock from "../components/PhotoBlock";
import { contentfulUrl } from "../urls";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "../Theme";
import { Title2 } from "../styles/styledComponents";
import { StyledDivGreen } from "./Gigs";

export default function Photos() {
	return (
		<ThemeProvider theme={defaultTheme}>
		<StyledDivGreen>
			<Title2>Photos</Title2>
			<PhotoBlock contentfulUrl={contentfulUrl} />
		</StyledDivGreen>
		</ThemeProvider>
	);
}
