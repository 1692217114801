import React from "react";
import GigsList from "../components/Gigs";
import { contentfulUrl } from "../urls";
import styled, { ThemeProvider } from "styled-components";
import { defaultTheme } from "../Theme";
import greenBackground from '../images/BackgroundGreen.png'


export const StyledDivGreen = styled.div`
	background-color: ${props => props.theme.backgroundColour};
	background-image: ${`url(${greenBackground})`};
	padding: 20px;
	min-height: 100vh;
	@media screen and (max-width: 768px) {
		margin-top: 15vh;
	}
`

export default function Gigs() {
	return (
		<ThemeProvider theme={defaultTheme}>
		<StyledDivGreen>
			<GigsList
				contentfulUrl={contentfulUrl}
				displayMap={true}
				displayPastGigs={true}
				testCase="none"
			></GigsList>
		</StyledDivGreen>
		</ThemeProvider>
	);
}
