import { styled } from "styled-components";
import { Link } from "react-router-dom";

export const LogoHeader = styled.img`
    width: 20%;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 0;
    @media screen and (max-width: 768px) {
        margin: auto;
        width: 40%;
    }
`

interface TitleProps {
    otherColor?: boolean
}

export const Title2 = styled.h2<TitleProps>`
    font-family: ${props => props.theme.fontFamily};
	font-size: ${props => props.theme.secondTitleFontSize};
	color: ${props => props.otherColor ? props.theme.primaryColorText : props.theme.thirdColorText};
	`

export const Title3 = styled.h3<TitleProps>`
    font-size: ${props => props.theme.thirdTitleFontSize};
    color: ${props => props.otherColor ? props.theme.thirdColorText : props.theme.secondaryColorText};
`

export const StyledLinkTitle = styled(Link)`
    color: ${props => props.theme.secondaryColorText};
    font-size: ${props => props.theme.TitleFontSize};
    width: 65%;
    font-weight: bolder;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    float: left;
    margin: 5px;
    text-align: left;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
`;

export const StyledLinkText = styled(Link)`
    color: ${props => props.theme.secondaryColorText};
`;

export const StyledList = styled.ul`
    font-size: ${props => props.theme.smallFontSize};
    color: ${props => props.theme.thirdColorText};
    list-style: none;
    padding: 0  ;
`

export const MainText = styled.p`
    font-size: ${props => props.theme.mainFontSize};
    color: ${props => props.theme.thirdColorText};
`

export const SmallText = styled.p`
    font-size: ${props => props.theme.smallFontSize};
    color: ${props => props.theme.secondaryColorText};
    margin-bottom: 0;
`

export const bulkText = styled.p`
    font-size: ${props => props.theme.mainFontSize};
    color: ${props => props.theme.secondaryColorText};
`

export const NewsContainer = styled.div`
    width: 80%;
    margin: 20px auto;
    padding: 10px;
    border-style: dashed;  
    border-color: ${props => props.theme.primaryColorText};
    border-width: thick;
    min-height: auto;
    background: ${props => props.theme.fourthColor};
`

export const NewsContent = styled.div`
    clear: left;
    display: flex;
    flex-direction: row;
    align-items: left;
    width: 100%;
    height:fit-content;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`

export const NewsDate = styled.p`
    font-size: ${props => props.theme.mainFontSize};
    float: right;
    width: 30%;
    text-align: right;
    list-style: none;
    margin: 5px;
    @media screen and (max-width: 768px) {
        width: 50%;
    }
`
export const NewsPhoto = styled.img`
    max-width: 80%;
    max-height: 250px;
    margin: 5px;
    float: right;
  `
  