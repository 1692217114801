import React from "react";
import NewsItems from "../components/NewsItems";
import { contentfulUrl } from "../urls";
import styled, { ThemeProvider } from "styled-components";
import { defaultTheme } from "../Theme";
import { StyledDivGreen } from "./Gigs";

export const StyledDivBlack = styled.div`
	background-color: #1d2929;
	padding: 20px;
`

export default function News() {
	return (
		<div>
		<ThemeProvider theme={defaultTheme}>
			<StyledDivGreen>
			<NewsItems
				contentfulUrl={contentfulUrl}
				displayLatestOnly={false}
			></NewsItems>
			</StyledDivGreen>
		</ThemeProvider>
		</div>
	);
}
