import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Hamburger from "hamburger-react";
import { styled, css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faApple,
	faInstagram,
	faSoundcloud,
	faSpotify,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import floriginLogo from '../images/Logo_Hex.svg'
import { LogoHeader } from "../styles/styledComponents";

export interface HeaderProps {
	isOpen: boolean
	headerOrFooter: 'header' | 'footer'
}
  
const HeaderComp = styled.header<HeaderProps>`
	background: ${props => props.theme.backgroundColour};
	color: ${props => props.theme.secondaryColorText};
	font-size: ${props => props.theme.mainFontSize};
	display: flex;
  	flex-direction: column;
  	align-items: center;
	z-index: 1;
	opacity: 100%;
	@media screen and (max-width: 768px) {
		position: fixed;
		height: ${props => props.isOpen ? '100vh' : '15vh'};
		display: flex;
		width: 100%;
		z-index: ${props => props.isOpen ? 4 : 1};
		top: 0;
		transition: height 0.5s;
	}
	`

	/*
	nav.active ul {
		background-color: rgba(0, 0, 0, 0.6);
			
	  }
	*/

const NavBar = styled.nav<HeaderProps>`
	ul {
		width: 100%;
		list-style: none;
		margin: 0;
		margin-top: 10px;
		padding: 0;
		display: flex;
		flex-direction: row;
		align-content: center;
		justify-content: space-between;
		@media screen and (max-width: 768px) {
			background-colour: ${props => props.theme.backgroundColour};
			flex-direction: column;
			align-items: center;
			position: fixed;
			padding-top: 20px;
			top: 0rem;
			left: 0;
			font-size: 2rem;
			line-height: 3rem;
			margin-top: 0;
			margin-left: 50%;
			margin-bottom: ${props => props.isOpen ? '100px' : '0'};
			opacity: ${props => props.isOpen ? '90%' : '0%'};
			z-index: ${props => props.isOpen ? '3' : '1%'};
			width: 0;
			height: ${props => props.isOpen ? 'fit-content' : '0'};
			transition: opacity 1s;
		}
	}
	li {
		margin: 0 1rem;
		margin-bottom: 10px;
	}
	a {
		color: ${props => props.theme.secondaryColorText};
		text-decoration: none;
		@media screen and (max-width: 768px) {
			z-index: 3;
			opacity: ${props => props.isOpen ? '100%' : '0%'};
		}
	}
`

export const SocialIcons = styled.div<HeaderProps>`
	display: flex;
	min-height: 20px;
	width: ${props => props.headerOrFooter == 'header' ? '25%': '80%'};
	justify-content: space-between;
	align-items: center;
	margin: ${props => props.headerOrFooter == 'footer' ? '0': 'auto'};
	margin-bottom: 5px;
	margin-top: 5px;
	a {
		text-decoration: solid;
		color: ${props => props.theme.secondaryColorText};
	}
	@media screen and (max-width: 768px) {
	width: 80%;
	margin-left: ${props => props.headerOrFooter == 'header' ? '10%' : '0'};
	display: ${props => props.isOpen ? 'none' : 'flex'}
	}
  }
`

const BurgerMenu = styled.div<HeaderProps>`
  	display: none;
	@media screen and (max-width: 768px) {
		display: block;
		align-items: left;
		margin-top: 5px;
		position: fixed;
		// top: 1vh;
		left: 2vh;
		z-index: 5;
	}
`

function preventScroll(e: Event | WheelEvent | TouchEvent) {
	e.preventDefault();
	e.stopPropagation();
	return false;
  }

function disableScroll() {
	window.addEventListener('scroll', preventScroll, { passive: false });
	window.addEventListener('wheel', preventScroll, { passive: false });
	// For touch devices
	window.addEventListener('touchmove', preventScroll, { passive: false });
  }
  
  // Function to enable scrolling
  function enableScroll() {
	window.removeEventListener('scroll', preventScroll);
	window.removeEventListener('wheel', preventScroll);
	// For touch devices
	window.removeEventListener('touchmove', preventScroll);
  }


function Header() {
	const [isOpen, setOpen] = useState(false);
	const [isInMediaView, setIsInMediaView] = useState(false);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 768px)');
		const handleChange: (ev: MediaQueryList) => void = (e) => {
			setIsInMediaView(e.matches);
		  };

		handleChange(mediaQuery)
	}, []);

	const handleHamburgerClick = (() => {
		if (!isInMediaView) {
			enableScroll()
			return
		}
		if (!isOpen) {
			disableScroll()
			setOpen(!isOpen)
		} else {
			enableScroll()
			setOpen(!isOpen)
		}
	})
	

	return (
		<HeaderComp isOpen={isOpen} headerOrFooter="header">
			<NavBar isOpen={isOpen} headerOrFooter="header" onClick={handleHamburgerClick}>
				<ul>
					<li>
						<NavLink to="/">Home</NavLink>
					</li>
					<li>
						<NavLink to="/gigs">Gigs</NavLink>
					</li>
					<li>
						<NavLink to="/photos">Photos</NavLink>
					</li>
					<li>
						<NavLink to="/merch">Merch</NavLink>
					</li>
					<li>
						<NavLink to="/about">About</NavLink>
					</li>
					<li>
						<NavLink to="/news">News</NavLink>
					</li>
					
				</ul>
			</NavBar>
			<BurgerMenu isOpen={isOpen} headerOrFooter="header">
				<Hamburger  toggled={isOpen} toggle={handleHamburgerClick} />
			</BurgerMenu>
			<LogoHeader style={{marginBottom: (isInMediaView)? (isOpen ? "80px": "") : ""}} src={floriginLogo} className="Florigin-logo" alt="Florigin" />
			<SocialIcons isOpen={isOpen} headerOrFooter="header">
				<a href="https://www.youtube.com/channel/UCw-4Hs42k567JbGiEGyBIFw">
					<FontAwesomeIcon icon={faYoutube} />
				</a>
				<a href="https://open.spotify.com/artist/2q5bQQI9tAIkAgzzU5JzdA?si=U8WUFT8sRE-IGR7IMfgu-g&referral=labelaffiliate&utm_source=1011lwISp5nj&utm_medium=Indie_Distrokid&utm_campaign=labelaffiliate&nd=1">
					<FontAwesomeIcon icon={faSpotify} />
				</a>
				<a href="https://www.instagram.com/floriginmusic/">
					<FontAwesomeIcon icon={faInstagram} />
				</a>
				<a href="https://music.apple.com/us/artist/florigin/1522550336">
					<FontAwesomeIcon icon={faApple} />
				</a>
				<a href="https://soundcloud.com/user-412069159">
					<FontAwesomeIcon icon={faSoundcloud} />
				</a>
			</SocialIcons>
		</HeaderComp>
	);
}

export default Header;
