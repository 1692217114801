import React, { useEffect, useState } from "react";
import { getContentful } from "../lib/getContentful";
import ReactHtmlParser from "react-html-parser";
import { styled } from "styled-components";

type BandInfo = {
	bandName: string;
	aboutText: string;
	photo: {
		title: string;
		description: string;
		url: string;
		width: number;
		height: number;
	};
};

type AboutProps = {
	contentfulUrl: string;
};

export default function AboutContent(props: AboutProps) {
	const [info, setInfo] = useState<BandInfo | undefined>();
	const [parsedAboutText, setParsedAboutText] = useState<
		React.ReactNode | undefined
	>();
	const contentKey = "bandInfo";
	const contentId = "4nWy68ynMWkH9dzrmtoWBL";
	const contentfulUrl = props.contentfulUrl;

	const query = `
  {
    ${contentKey}(id: "${contentId}") {
				aboutText 
				photo {
					url
					title
				}
			}
    }
    `;

	useEffect(() => {
		const fetchData = async () => {
			const data = await getContentful<Record<string, BandInfo>>(
				contentfulUrl,
				query
			);
			setInfo(data?.[contentKey]);
		};
		fetchData();
	}, [contentfulUrl, query]);

	useEffect(() => {
		if (info) {
			console.log(info.aboutText);
			setParsedAboutText(ReactHtmlParser(info?.aboutText));
		}
	}, [info]);


	const AboutDiv = styled.div`
	
	font-size: ${props => props.theme.mainFontSize};
	color: ${props => props.theme.thirdColorText};
	background: ${props => props.theme.fourthColor};
	border-style: dashed;  
    border-color: ${props => props.theme.primaryColorText};
    border-width: medium;
	padding: ${props => props.theme.borderRadius};
	
	img {
		width: 60%;
		padding: 10px;
	@media screen and (max-width: 768px) {
		width: 100%;
		padding-left: 0;
		}
	}
	.bandMember {
		color: ${props => props.theme.secondaryColorText}
	}
	a:link {
		color: ${props => props.theme.secondaryColorText}
	}
	a:visited {
		color: ${props => props.theme.secondaryColorText}
	}
	`

	if (!info) {
		return <p>Loading...</p>;
	} else {
		return (
		<AboutDiv>{parsedAboutText}
		<img src={info?.photo.url} />
		</AboutDiv>
		);
	}
}
