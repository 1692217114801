import React from "react";
import { Gig } from "./Gigs";
import "../styles/Gigs.css";
import { Link } from "react-router-dom";
import { MainText, StyledLinkText, StyledList, Title2, Title3 } from "../styles/styledComponents";

type GigListProps = {
	gigs: Gig[];
	displayPastGigs: boolean;
	future_gigs: boolean;
};



export default function GigList({ gigs, displayPastGigs, future_gigs }: GigListProps) {
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		//timeZoneName: "long",
	};
	
	let previousGigsSubtitle = "";
	if (displayPastGigs) {
		previousGigsSubtitle = "Previous Gigs";
	}
	let noFutureGigsMessage = "";
	if (!future_gigs) {
		noFutureGigsMessage = "Information on future gigs coming soon! \n For bookings contact floriginmusic@gmail.com"
	}

	return (
		<div>
			<Title2>Upcoming Concerts</Title2>
			<MainText dangerouslySetInnerHTML={{ __html: noFutureGigsMessage.replace('\n', '<br />')}} />
			<ul className="gigList">
				{gigs
					.filter((gig) => Date.parse(gig.dateAndTime) > Date.now())
					.sort((a, b) => {
						return Date.parse(a?.dateAndTime) - Date.parse(b?.dateAndTime);
					})
					.map((gig) => (
						<li className="gigItem" key={gig?.title} onClick={() => gig?.link}>
							<StyledList className="gigDetails">
								<li className="dateTime">
									{
										// TODO: add tyoes for toLocaleDateString arguments
										new Date(gig?.dateAndTime).toLocaleDateString(
											"en-GB",
											//@ts-ignore
											options
										)
									}
								</li>
								<li className="cityCountry">{gig.citycountry}</li>
								<li className="venue">
									<StyledLinkText to={gig?.link}> {gig?.title}</StyledLinkText>	
								</li>
							</StyledList>
						</li>
					))}
			</ul>
			<Title3>{previousGigsSubtitle}</Title3>
			<ul className="gigList">
				{gigs
					.filter((gig) => Date.parse(gig.dateAndTime) < Date.now())
					.sort((a, b) => {
						return Date.parse(b?.dateAndTime) - Date.parse(a?.dateAndTime);
					})
					.map((gig) => (
						<li className="gigItem" key={gig?.title} onClick={() => gig?.link}>
							<StyledList className="gigDetails">
								<li className="dateTime">
									{
										// TODO: add tyoes for toLocaleDateString arguments
										new Date(gig?.dateAndTime).toLocaleDateString(
											"en-GB",
											//@ts-ignore
											options
										)
									}
								</li>
								<li className="cityCountry">{gig.citycountry}</li>
								<li className="venue">
									<StyledLinkText to={gig?.link}> {gig?.title}</StyledLinkText>	
								</li>
							</StyledList>
						</li>
					))}
			</ul>
		</div>
	);
}

function getCityCountry(arg0: number, arg1: number) {
	throw new Error("Function not implemented.");
}
