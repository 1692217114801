import React, { useEffect, useState } from "react";
import "./styles/App.css";
import Header from "./components/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Gigs from "./pages/Gigs";
import About from "./pages/About";
import Photos from "./pages/Photos";
import News from "./pages/News";
import Contact from "./pages/Contact";
import Videos from "./pages/Videos";
import Merch from "./pages/Merch";
import { getRandomPhoto } from "./lib/getRandomPhoto";
import Footer from "./components/Footer";
import { ThemeProvider } from "styled-components";
import { FontStyles, defaultTheme } from "./Theme";

function App() {
	const [backgroundPhotoUrl, setBackgroundPhotoUrl] = useState<
		string | undefined
	>();
	console.log(backgroundPhotoUrl);
	useEffect(() => {
		const fetchPhoto = async () => {
			const randomPhotoUrl = await getRandomPhoto();
			setBackgroundPhotoUrl(randomPhotoUrl);
		};
		fetchPhoto();
	}, []);
	return (
		<BrowserRouter>
			<div
				className="App"
			>
				<ThemeProvider theme={defaultTheme}>
				<FontStyles />
				<Header />
				</ThemeProvider>
				<div className="content">
					<Routes>
						<Route path="/" element={<Home backgroundImageUrl={backgroundPhotoUrl}/>} />
						<Route path="/gigs" element={<Gigs />} />
						<Route path="/photos" element={<Photos />} />
						<Route path="/merch" element={<Merch />} />
						<Route path="/about" element={<About />} />
						<Route path="/news" element={<News />} />
					</Routes>
				</div>
			</div>
			<ThemeProvider theme={defaultTheme}>
			<FontStyles />
				<Footer />
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
