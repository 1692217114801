import { createGlobalStyle } from "styled-components";
import poppinsFontBold from './styles/fonts/Poppins/Poppins-Bold.ttf'
import poppinsFontRegular from './styles/fonts/Poppins/Poppins-Regular.ttf'

export interface ThemeSchema {
    primaryColorText?: string
    secondaryColorText?: string
    thirdColorText?: string
    fourthColor?: string
    fifthColor?: string
    mainFontSize?: string
    smallFontSize?: string
    mainTitleFontSize?: string
    secondTitleFontSize?: string
    thirdTitleFontSize?: string
    borderColor?: string
    borderRadius?: string
    backgroundColour: string
    homeBlockHeight: number
  }

export const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'PoppinsBold';
    src: url(${poppinsFontBold}) format('truetype'); 
  }
  @font-face {
    font-family: 'PoppinsRegular';
    src: url(${poppinsFontRegular}) format('truetype'); 
  }

  body {
    font-family: 'PoppinsRegular', sans-serif;
    /* You can set fallback fonts or other styles for the body here */
  }
  h2 {
    font-family: 'PoppinsBold', sans-serif;
  }
`

export const defaultTheme: ThemeSchema = {
    primaryColorText: '#3CA9A5',
    secondaryColorText: '#ffffff',
    thirdColorText: '#000000',
    fourthColor: '#1A6d2c9c', // first two numbers are transparency
    fifthColor: '#2AC8C5',
    mainFontSize: '18px',
    smallFontSize: '14px',
    mainTitleFontSize: '46px',
    secondTitleFontSize: '38px',
    thirdTitleFontSize: '26px',
    borderColor: 'purple',
    borderRadius: '5px',
    backgroundColour: '#262626',
    homeBlockHeight: 850
}


