import React, { useEffect, useState } from "react";
import "../styles/News.css";
import { Link } from "react-router-dom";
import { getContentful } from "../lib/getContentful";
import { MainText, NewsContainer, NewsContent, NewsDate, NewsPhoto, StyledLinkTitle, StyledList, Title2 } from "../styles/styledComponents";

type NewsProps = {
	contentfulUrl: string;
	displayLatestOnly: boolean;
};

export interface News {
	title: string;
	mainText: string;
	date: string;
	link: string;
	photo: {
		title: string;
		description: string;
		url: string;
		width: number;
		height: number;
	};
}

type NewsItems = Record<"items", News[]>;

export default function NewsItems(props: NewsProps) {
	const [news, setNews] = useState<NewsItems | undefined>();
	const contentKey = "newsCollection";
	const contentfulUrl = props.contentfulUrl;
	const displayLatestOnly = props.displayLatestOnly;
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	const query = ` query GetNews
  {
    ${contentKey} {
        items {
        title
        mainText
        date
        link
        photo {
          title
          description
          url
          width
          height
        }
        }
        
    }
    }
    `;

	useEffect(() => {
		const fetchData = async () => {
			const data = await getContentful<Record<string, NewsItems>>(
				contentfulUrl,
				query
			);
			setNews(data?.[contentKey]);
		};
		fetchData();
	}, [contentfulUrl, query]);

	if (!news) {
		return <p>Loading News...</p>;
	}

	if (displayLatestOnly) {
		news.items = [
			news.items.sort((a, b) => {
				return Date.parse(b?.date) - Date.parse(a?.date);
			})[0],
		];
	}

	return (
		<div>
			<Title2>Latest News</Title2>
			<StyledList className="news">
				{news.items
					.sort((a, b) => {
						return Date.parse(b?.date) - Date.parse(a?.date);
					})
					.map((newsItem) => (
						<li
							className="newsItem"
							key={newsItem?.title}
							onClick={() => newsItem?.link}
						>
							<NewsContainer>
								<NewsDate>
									{new Date(newsItem?.date).toLocaleDateString(
										"en-GB",
										//@ts-ignore
										options
									)}
								</NewsDate>
								<h2>
									<StyledLinkTitle to={newsItem?.link} className="newsTitle">
										{newsItem?.title}
									</StyledLinkTitle>
								</h2>
								<NewsContent>
									<MainText style={{textAlign: "left"}}> {newsItem.mainText} </MainText>

									<NewsPhoto src={newsItem?.photo?.url}></NewsPhoto>
								</NewsContent>
							</NewsContainer>
						</li>
					))}
			</StyledList>
		</div>
	);
}
